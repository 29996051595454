import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/index.vue";
// import { mapState,mapMutations} from "vuex"
import store from '../store/index'



// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Home,
        meta: {
            keepAlive: true, // 需要缓存
            
        }
    },
    // 首页
    {
        path: '/home',
        component: () => import("../views/Home/index.vue"),
        meta: {
            keepAlive: true, // 需要缓存
         

        }
    },
    // 关注
    {
        path: '/follow',
        component: () => import("../views/Follow/index.vue"),
        meta: {
            keepAlive: true // 需要缓存
        }
    },
    // 个人中心
    {
        path: '/mine',
        component: () => import("../views/Mine/index.vue")
    },
    {
        path: '/login',
        component: () => import("../views/Login/index.vue")
    },
    {
        path: '/register',
        component: () => import("../views/Register/index.vue")
    },
    // 发布
    {
        path: '/post',
        component: () => import("../views/Post/index.vue")
    },
    // 详情
    {
        path: '/detail',
        component: () => import('../views/Detail/index.vue')
    },
    {
        path: '/detail_ylyf',
        component: () => import('../views/Detail_ylyf/index.vue')
    },
    {
        path: '/detail_GM',
        component: () => import('../views/Detail_GM/index.vue')
    },
    // 搜索
    {
        path: '/search',
        component: () => import('../views/Search/index.vue'),
        meta: {
            keepAlive: true // 需要缓存
        }
    },
    // 日志
    {
        path: '/logs',
        component: () => import('../views/Logs/index.vue'),
        meta: {
            keepAlive: true // 需要缓存
        }
    },
     // 代理
    {
        path: '/agent',
        component: () => import('../views/Agent/index.vue')
    },
     // 推广
     {
        path: '/ads',
        component: () => import('../views/Ads/index.vue'),
        // meta: {
        //     keepAlive: true // 需要缓存
        // }
    },
    // 二维码
    {
        path: '/QrCode',
        component: () => import('../views/QrCode/index.vue'),
        // meta: {
        //     keepAlive: true // 需要缓存
        // }
    },
    // 换购
    {
        path: '/Replace',
        component: () => import('../views/Hgou/index.vue'),
        // meta: {
        //     keepAlive: true // 需要缓存
        // }
    },
    // 更多应用
    {
        path: '/moreFun',
        component: () => import('../views/Morefun/index.vue'),
        // meta: {
        //     keepAlive: true // 需要缓存
        // }
    },
     // 更多应用
     {
        path: '/moreApp',
        component: () => import('../views/MoreApp/index.vue'),
        meta: {
            keepAlive: true // 需要缓存
        }
    },
    // 超管
    {
        path: '/Super',
        component: () => import('../views/Super/index.vue'),
        beforeEnter: (to, from, next) => {
            // console.log(store.state.user.userInfo.usertype,7);
            if (store.state.user.userInfo.usertype !== '999') {
                // console.log(899);
                next({
                    path: "/"
                })
            } else { 
                next()
            }
        }
    },
    // 日志
    {
        path: '/Record',
        component: () => import('../views/Record/index.vue'),
        beforeEnter: (to, from, next) => {
            // console.log(store.state.user.userInfo.usertype,7);
            if (store.state.user.userInfo.usertype !== '999') {
                // console.log(899);
                next({
                    path: "/"
                })
            } else { 
                next()
            }
        }
    },
       // 兑换活动
       {
        path: '/Activity',
        component: () => import('../views/Activity/index.vue'),
        // beforeEnter: (to, from, next) => {
        //     // console.log(store.state.user.userInfo.usertype,7);
        //     if (store.state.user.userInfo.usertype !== '999') {
        //         // console.log(899);
        //         next({
        //             path: "/"
        //         })
        //     } else { 
        //         next()
        //     }
        // }
    },
    {
        path: '*',
        redirect: '/home'
    }

]



var router =  new VueRouter({
    mode: 'hash',//改为history模式，去除url里的#
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }    
})



export default router;
