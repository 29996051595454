import Vue from 'vue'
import App from './App.vue'
import 'lib-flexible'
import Vant from 'vant';
import 'vant/lib/index.css';
import { getNYRSFM } from "./model/user.js";




import { setCookie, getCookie, checkCookie, clearCookie,operlog } from './utils/cookie';

Vue.prototype.$setCookie = setCookie;
Vue.prototype.$getCookie = getCookie;
Vue.prototype.$checkCookie = checkCookie;
Vue.prototype.$clearCookie = clearCookie;
Vue.prototype.$operLog = operlog
Vue.prototype.$getNYRSFM = getNYRSFM

import store from './store/index'


Vue.use(Vant);

import router from "./router/index"

import { Lazyload } from 'vant';
Vue.use(Lazyload);
import { Divider } from 'vant';
Vue.use(Divider);



import _debounce from "./mixins/index.js"
Vue.mixin(_debounce);



Vue.config.productionTip = false
// 时间戳格式转换
// Vue.filter('dateFormat', function(originVal) {
//   const dt = new Date(originVal)
//   const y = dt.getFullYear()
//   const m = (dt.getMonth() + 1 + '').padStart(2, '0')
//   const d = (dt.getDate() + '').padStart(2, '0')
//   const hh = (dt.getHours() + '').padStart(2, '0')
//   const mm = (dt.getMinutes() + '').padStart(2, '0')
//   const ss = (dt.getSeconds() + '').padStart(2, '0')
//   //时间格式年月日、时分秒
//   return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
// })

// router.beforeEach((to, from, next) => {
//   console.log('前置路由守卫beforeEach')
//   // console.log(to);
//   // console.log(store.state.user.userInfo.usertype);
//   if (to.path === "/Super" && store.state.user.userInfo.usertype == "999") {
//     console.log(66);
//     next()
//   } else { 
//     next({
//       path:'/mine'
//     })
//   }
// })

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
