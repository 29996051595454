<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->

  <!-- <router-view> </router-view> -->
  <keep-alive>
		<router-view v-if="$route.meta.keepAlive"></router-view>
	</keep-alive>
	<router-view v-if="!$route.meta.keepAlive"></router-view>

  <!-- <div class="navBar"> -->
    <van-tabbar v-if="flag" route class="navBar" v-model="activeBtn" @change="onChange" fixed  active-color="#ec4899">
      <van-tabbar-item replace to="/home" name="home" icon="home-o" :class=" activecolor">51风流</van-tabbar-item>
      <van-tabbar-item replace to="/follow" name="follow" icon="guide-o">关注</van-tabbar-item>
      <van-tabbar-item replace to="/moreApp" name="post" icon="vip-card-o">一楼一凤</van-tabbar-item>
      <!-- <van-tabbar-item replace to="/post" name="post" icon="records">发布</van-tabbar-item> -->
      <van-tabbar-item replace to="/mine" name="mine"  icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  <!-- </div> -->
  </div>
</template>

<script>


import {mapMutations} from "vuex"
import { uploginState } from "./model/user"
import bus from "./utils/bus"


export default {
  name: 'App',
  components: {
    
  },
  data() {
    return {
      barVisiale:true,
      activeBtn: "",
      activecolor: "activecolor",
      userInfo:{},
      timer:{},
      aaa:null
    }
  },

  created() {
    // 持久化
    this.$store.commit('INIT_USER')
    bus.$on('info',(e)=>{
      // console.log(e,"新增");
      this.userInfo = e
    })

  },
  mounted() {  
     
  document.addEventListener('visibilitychange', this.handleVisiable)  
},  
destroyed() {  
  document.removeEventListener('visibilitychange', this.handleVisiable)  
},

    computed: {
   
  
    flag(){
         
           return this.$store.state.user.flag
        },
  },
  methods: {
    ...mapMutations(['loginOut']),
    Tc(){
        let data = {
          loginState : 0,
          username : this.userInfo.username
        }
        console.log(data);
        uploginState(data).then((res)=>{
          if(res){
            console.log("退出");
          }
        })
      },
    onChange(){
        this.activecolor = null
    },
    time(){
       setTimeout(()=>{
          this.Tc()
          this.loginOut();
        },2000)
    },
     handleVisiable(e) {  
    switch(e.target.visibilityState) {
      case 'prerender':{
        // console.log('网页预渲染，内容不可见')
        break;
        }
      case 'hidden':{
        // console.log('内容不可见，处理后台、最小化、锁屏状态')
        // alert('警告！你已经离开当前答题页面');
      //  this.time()
        this.aaa = setTimeout(()=>{
          // setTimeout(()=>{
            this.Tc()
          // },2000)
           this.loginOut();
          
        },14400000)
        // 14400000
        break;
      }
      case 'visible':{
        // console.log('处于正常打开')
        clearTimeout(this.aaa)
        break;
      }
    }
  }


    

  },
}
</script>

<style scped>
html,body,#app{
  height:100vh;
  width:100vw;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
#app .navBar{
  width: 100%;
  height: 40px;
  /* background-color: #cccc; */
  position: fixed;
  bottom: 0;
  left: 0;
  /* display: flex; */
} 
.activecolor {
  color: #ec4899 !important;
}
.noticeAAA{
  color: #ec4899 !important;
  /* background-color: #ec4899 !important; */
  background-color: #00000000 !important;
  font-size: 18px !important;
}
</style>
