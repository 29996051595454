<template>
  <div id="container">
    <!--  -->
      
    <!--  -->
    <div style="height: 60px">
      <div class="header">
        <div class="title">
          <ul
            v-for="(item, index) in title"
            :key="item.id"
            @click="change(index)"
            :class="[actIndex == index ? 'activecolor' : '']"
          >
            <li>{{ item.title }}</li>
          </ul>
        </div>
        <!-- <div class="search">
          <van-icon @click="searchBox" name="search" size="24" />
        </div> -->
      </div>
    </div>
    <!-- 热门 -->
    <div class="content el-main" v-if="rm">
      <div class="ads" v-if="ads">
        <div class="ld">
            <img src="../../assets/images/lingd.jpg" alt="">
        </div>
        <!-- <van-icon name="cross" class="cross" @click="closeAds()" />
        <div class="head">系统公告</div>
        <div class="font" style="margin-top:10px;text-align:left">
          <span style="color:#ec4899">新人福利:</span>新用户注册即送68积分,可用于解锁帖子！<br>
          <span style="color:#ec4899">限时福利:</span>永久会员仅需158元,购买季度会员升级,自动成为永久会员！<br>
          <span style="color:#ec4899">推广系统:</span>用户每邀请一位用户注册,即可获取5积分,邀请用户开通会员可获得佣金35%,实时到账,
          佣金可用于提现,以及开通会员消费等等。<br>
          <span style="color:#ec4899">温馨提示:</span>交友需谨慎,遇到开口就是车费,预约金的情况,大家多留意,小心上当受骗！尽量当面交易,保证安全！
        </div> -->
        <div>
          <span style="color:#ec4899">最新域名:51ym.xyz,截图保存！！！</span><br>
          <span style="color:#ec4899">新域名:16ym.xyz,截图保存！！！</span><br>
          <span style="color:#ec4899">新域名:91gpw.xyz,截图保存！！！</span><br>
          <!-- <span style="color:#ec4899">图片看不到请打开加速器！！！</span><br> -->
          <!-- 积分可用于解锁风流贴！<br> -->
          <!-- 特价最后一天，永久会员只要88元！<br>限时限量，手快有，手慢无！<br>
           新用户注册即送68积分！<br> -->
          <!-- 永久会员开通仅需88元！<br>购买季度会员升级,自动成为永久会员！<br> -->
          永久会员开通仅需88元！<br>
          新用户注册领200积分!<br>

          <!-- 温馨提示:交友需谨慎,遇到开口就是车费,预约金的情况,大家多留意,小心上当受骗！尽量当面交易,保证安全！ -->
        </div>
        <div class="closeBtn" @click="closeAds()">
          我知道啦
        </div>
      </div>
      <van-dialog v-model="visable" title="公告" :show-cancel-button="false" confirm-button-text="已知晓" confirm-button-color="#ec4899">
        <!-- <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> -->
        <div style="font-size: 14px;margin-bottom: 6px">
          做全网最全的楼凤信息<br>
        </div>
        <div style="font-size: 14px;margin-bottom: 6px">
          拒绝中介 信息来源狼友真实体验分享<br>
        </div>
        <div style="font-size: 14px;margin-bottom: 6px">
          <span style="color:#ec4899">51风流、一楼一凤</span> 信息均已提供<br>
        </div>
        <div style="font-size: 14px;margin-bottom: 6px">
          会员可享<span style="color:#ec4899"> 精品应用 </span>更多账号特权<span style="color:#ec4899"></span><br>
        </div>
        <div style="font-size: 14px;margin-bottom: 6px">
          新用户注册领200积分<br>
        </div>
        <div style="font-size: 14px;margin-bottom: 6px;color:#ec4899">
          备用地址：61hx.xyz 16ym.xyz<br>
        </div>
      </van-dialog>
            
      <div class="table">
        <van-pull-refresh
          v-model="refreshing" @refresh="onRefresh"
          success-text="刷新成功"
          loading-text="刷新中"
        >
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
        <div
          class="list"
          v-for="(item,index) in dataList"
          :key="item.id+index"
          @click="detail(item)"
        >
          <div :class="item.picture==''?'left1':'left'">
          <!-- <div class="left1"> -->

            <ul style="text-align: left; heigth: 100%">
              <li style="font-size: 16px; margin-bottom: 3px">
                {{ item.title }}
              </li>
              <div style="color: #a3a3a3; line-height: 30px">
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="clock-o"
                /><span style="font-size: 12px; margin-right: 5px">{{
                  filterTime(item.publishedAt)
                }}</span>
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="eye-o"
                /><span style="font-size: 12px">{{ item.viewCount }}</span>
              </div>
              <div
                style="font-size:14px;width:100%;height:58px; color:#666;
                            display:-webkit-box;
                                overflow:hidden;
                                text-overflow:ellipsis;
                                -webkit-line-clamp:3;
                                -webkit-box-orient:vertical;"
              >
                {{ item.desc }}
              </div>
              <div
                class="bottom"
                style="height: 30px; line-height: 30px; margin-top: 10px"
              >
                <span
                  ><van-icon
                    name="guide-o"
                    size="18"
                    color="#ec4899"
                    style="margin-right: 5px" /></span
                ><span class="city" style="font-size: 14px;color:'#ec4899'">{{filterCity(item.cityCode)}}</span>
              </div>
            </ul>
          </div>
          <div class="right" v-if="item.coverPicture != '' && item.coverPicture != null">
            <!-- <img
              v-if="item.coverPicture.indexOf('/') != -1"
              :src="'http://38.60.204.69/index.php?url='+baseUrl+item.coverPicture+'&refer=https://dx41.xyz/'"
              alt=""
              @error.once="moveErrorImg"
              referrerpolicy='no-referrer'
            />
            <img
              v-else
              :src="'http://38.60.204.69/index.php?url='+baseUrl+filterTimePIC(item.publishedAt)+'/'+item.coverPicture+'&refer=https://dx41.xyz/'"
              alt=""
              @error.once="moveErrorImg"
              referrerpolicy='no-referrer'
            /> -->
            <img
              v-if="item.coverPicture.indexOf('/') != -1"
              :src="baseUrl+item.coverPicture"
              alt=""
              @error.once="moveErrorImg"
              referrerpolicy='no-referrer'
            />
            <img
              v-else
              :src="baseUrl+filterTimePIC(item.publishedAt)+'/'+item.coverPicture"
              alt=""
              @error.once="moveErrorImg"
              referrerpolicy='no-referrer'
            />
          </div>
          <div class="right" v-else>
            <div class="right" style="width:100%" v-if="item.picture != '' && item.picture != null">
              <!-- <img
                v-if="item.picture.split(',')[0].indexOf('/') != -1"
                :src="'http://38.60.204.69/index.php?url='+baseUrl+item.picture.split(',')[0]+'&refer=https://dx41.xyz/'"
                alt=""
                @error.once="moveErrorImg"
                referrerpolicy='no-referrer'
              />
              <img
                v-else
                :src="'http://38.60.204.69/index.php?url='+baseUrl+filterTimePIC(item.publishedAt)+'/'+item.picture.split(',')[0]+'&refer=https://dx41.xyz/'"
                alt=""
                @error.once="moveErrorImg"
                referrerpolicy='no-referrer'
              /> -->
              <img
                v-if="item.picture.split(',')[0].indexOf('/') != -1"
                :src="baseUrl+item.picture.split(',')[0]"
                alt=""
                @error.once="moveErrorImg"
                referrerpolicy='no-referrer'
              />
              <img
                v-else
                :src="baseUrl+filterTimePIC(item.publishedAt)+'/'+item.picture.split(',')[0]"
                alt=""
                @error.once="moveErrorImg"
                referrerpolicy='no-referrer'
              />
            </div>
          </div>
        </div>
        </van-list>
      </van-pull-refresh>
      </div>
    </div>
    <!-- 最新 -->
    <div class="content el-main" v-if="zx">
     <div class="table">
        <van-pull-refresh
          v-model="refreshing1" @refresh="onRefresh1"
          success-text="刷新成功"
          loading-text="刷新中"
        >
        <van-list
            v-model="loading1"
            :finished="finished1"
            finished-text="没有更多了"
            @load="onLoad1"
          >
        <div
          class="list"
          v-for="(item,index) in dataList1"
          :key="item.id+index"
          @click="detail(item)"
        >
          <div :class="item.picture==''?'left1':'left'">
          <!-- <div class="left1"> -->

            <ul style="text-align: left; heigth: 100%">
              <li style="font-size: 16px; margin-bottom: 3px">
                {{ item.title }}
              </li>
              <div style="color: #a3a3a3; line-height: 30px">
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="clock-o"
                /><span style="font-size: 12px; margin-right: 5px">{{
                  filterTime(item.publishedAt)
                }}</span>
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="eye-o"
                /><span style="font-size: 12px">{{ item.viewCount }}</span>
              </div>
              <div
               style="font-size:14px;width:100%;height:58px; color:#525252;
                            display:-webkit-box;
                                overflow:hidden;
                                text-overflow:ellipsis;
                                -webkit-line-clamp:3;
                                -webkit-box-orient:vertical;"
              >
                {{ item.desc }}
              </div>
              <div
                class="bottom"
                style="height: 30px; line-height: 30px; margin-top: 10px"
              >
                <span
                  ><van-icon
                    name="guide-o"
                    size="18"
                    color="#ec4899"
                    style="margin-right: 15px" /></span
                ><span class="city" style="font-size: 14px;color:'#ec4899'">{{filterCity(item.cityCode)}}</span>
              </div>
            </ul>
          </div>
          <!-- <div class="right" v-if="item.picture != ''">
            <img
              :src="baseUrl+filterTimePIC(item.publishedAt)+'/'+item.picture.split(',')[0]"
              alt=""
              @error.once="moveErrorImg"
            />
          </div> -->
          <div class="right" v-if="item.coverPicture != '' && item.coverPicture != null">
            <!-- <img
              v-if="item.coverPicture.indexOf('/') != -1"
              :src="'http://38.60.204.69/index.php?url='+baseUrl+item.coverPicture+'&refer=https://dx41.xyz/'"
              alt=""
              @error.once="moveErrorImg"
              referrerpolicy='no-referrer'
            />
            <img
              v-else
              :src="'http://38.60.204.69/index.php?url='+baseUrl+filterTimePIC(item.publishedAt)+'/'+item.coverPicture+'&refer=https://dx41.xyz/'"
              alt=""
              @error.once="moveErrorImg"
              referrerpolicy='no-referrer'
            /> -->
            <img
              v-if="item.coverPicture.indexOf('/') != -1"
              :src="baseUrl+item.coverPicture"
              alt=""
              @error.once="moveErrorImg"
              referrerpolicy='no-referrer'
            />
            <img
              v-else
              :src="baseUrl+filterTimePIC(item.publishedAt)+'/'+item.coverPicture"
              alt=""
              @error.once="moveErrorImg"
              referrerpolicy='no-referrer'
            />
          </div>
          <div class="right" v-else>
            <div class="right" style="width:100%" v-if="item.picture != '' && item.picture != null">
              <!-- <img
                v-if="item.picture.split(',')[0].indexOf('/') != -1"
                :src="'http://38.60.204.69/index.php?url='+baseUrl+item.picture.split(',')[0]+'&refer=https://dx41.xyz/'"
                alt=""
                @error.once="moveErrorImg"
                referrerpolicy='no-referrer'
              />
              <img
                v-else
                :src="'http://38.60.204.69/index.php?url='+baseUrl+filterTimePIC(item.publishedAt)+'/'+item.picture.split(',')[0]+'&refer=https://dx41.xyz/'"
                alt=""
                @error.once="moveErrorImg"
                referrerpolicy='no-referrer'
              /> -->
              <img
                v-if="item.picture.split(',')[0].indexOf('/') != -1"
                :src="baseUrl+item.picture.split(',')[0]"
                alt=""
                @error.once="moveErrorImg"
                referrerpolicy='no-referrer'
              />
              <img
                v-else
                :src="baseUrl+filterTimePIC(item.publishedAt)+'/'+item.picture.split(',')[0]"
                alt=""
                @error.once="moveErrorImg"
                referrerpolicy='no-referrer'
              />
            </div>
          </div>
        </div>
        </van-list>
      </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>

import { getZXlist,getRMlist,getZXnum,getRMnum } from "../../model/index";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
// import { Dialog } from 'vant';

export default {
  data() {
    return {
      title: [
        { id: 1, title: "热门" },
        { id: 2, title: "最新" },
      ],
      actIndex: 0,
      rm: true,
      zx: false,
      // baseUrl: "https://r1.img7.xyz/info/picture/",
      // baseUrl: "http://38.54.111.82/info/picture/",
      baseUrl: "http://38.54.111.182/info/picture/",
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageNumber:1,
      total:20,
      dataList1: [],
      loading1: false,
      finished1: false,
      refreshing1: false,
      pageNumber1:1,
      RMnum:"",
      ZXnum:"",
      timeer:"",
      ads:false,
      scroll:0,
      province:[{id:1,cityCode:"110000",cityName:"北京市",city:[{id:1,cityCode:"110000",cityName:"北京市"},
      {id:2,cityCode:"110101",cityName:"东城区"},{id:3,cityCode:"110102",cityName:"西城区"},
      {id:4,cityCode:"110105",cityName:"朝阳区"},{id:5,cityCode:"110106",cityName:"丰台区"},
      {id:6,cityCode:"110107",cityName:"石景山区"},{id:7,cityCode:"110108",cityName:"海淀区"},
      {id:8,cityCode:"110109",cityName:"门头沟区"},{id:9,cityCode:"110111",cityName:"房山区"},
      {id:10,cityCode:"110112",cityName:"通州区"},{id:11,cityCode:"110113",cityName:"顺义区"},
      {id:12,cityCode:"110114",cityName:"昌平区"},{id:13,cityCode:"110115",cityName:"大兴区"},
      {id:14,cityCode:"110116",cityName:"怀柔区"},{id:15,cityCode:"110117",cityName:"平谷区"},
      {id:16,cityCode:"110118",cityName:"密云区"},{id:17,cityCode:"110119",cityName:"延庆区"},
      ]}, {id:2,cityCode:"120000",cityName:"天津市",city:[{id:1,cityCode:"120000",cityName:"天津市"},
      {id:1,cityCode:"120101",cityName:"和平区"},{id:1,cityCode:"120102",cityName:"河东区"},
      {id:1,cityCode:"120103",cityName:"河西区"},{id:1,cityCode:"120104",cityName:"南开区"},
      {id:1,cityCode:"120105",cityName:"河北区"},{id:1,cityCode:"120106",cityName:"红桥区"},
      {id:1,cityCode:"120110",cityName:"东丽区"},{id:1,cityCode:"120111",cityName:"西青区"},
      {id:1,cityCode:"120112",cityName:"津南区"},{id:1,cityCode:"120113",cityName:"北辰区"},
      {id:1,cityCode:"120114",cityName:"武清区"},{id:1,cityCode:"120115",cityName:"宝坻区"},
      {id:1,cityCode:"120116",cityName:"滨海新区"},{id:1,cityCode:"120117",cityName:"宁河区"},
      {id:1,cityCode:"120118",cityName:"静海区"},{id:1,cityCode:"120119",cityName:"蓟州区"},
      ]}, {id:3,cityCode:"130000",cityName:"河北省",city:[{id:1,cityCode:"130000",cityName:"河北省"},
      {id:2,cityCode:"130100",cityName:"石家庄市"},{id:3,cityCode:"130200",cityName:"唐山市"},
      {id:4,cityCode:"130300",cityName:"秦皇岛市"},{id:5,cityCode:"130400",cityName:"邯郸市"},
      {id:6,cityCode:"130500",cityName:"邢台市"},{id:7,cityCode:"130600",cityName:"保定市"},
      {id:8,cityCode:"130700",cityName:"张家口市"},{id:9,cityCode:"130800",cityName:"承德市"},
      {id:10,cityCode:"130900",cityName:"沧州市"},{id:11,cityCode:"131000",cityName:"廊坊市"},
      {id:12,cityCode:"131100",cityName:"衡水市"}]},
      {id:4,cityCode:"140000",cityName:"山西省",city:[{id:1,cityCode:"140000",cityName:"山西省"},
      {id:1,cityCode:"140100",cityName:"太原市"},{id:1,cityCode:"140200",cityName:"大同市"},
      {id:1,cityCode:"140300",cityName:"阳泉市"},{id:1,cityCode:"140400",cityName:"长治市"},
      {id:1,cityCode:"140500",cityName:"晋城市"},{id:1,cityCode:"140600",cityName:"朔州市"},
      {id:1,cityCode:"140700",cityName:"晋中市"},{id:1,cityCode:"140800",cityName:"运城市"},
      {id:1,cityCode:"140900",cityName:"忻州市"},{id:1,cityCode:"141000",cityName:"临汾市"},
      {id:1,cityCode:"1401100",cityName:"吕梁市"}]},
      {id:5,cityCode:"150000",cityName:"内蒙古自治区",city:[{id:1,cityCode:"150000",cityName:"内蒙古自治区"},
      {id:2,cityCode:"150100",cityName:"呼和浩特市"},{id:3,cityCode:"150200",cityName:"包头市"},
      {id:4,cityCode:"150300",cityName:"乌海市"},{id:5,cityCode:"150400",cityName:"赤峰市"},
      {id:6,cityCode:"150500",cityName:"通辽市"},{id:7,cityCode:"150600",cityName:"鄂尔多斯市"},
      {id:8,cityCode:"150700",cityName:"呼伦贝尔市"},{id:9,cityCode:"150800",cityName:"巴彦淖尔市"},
      {id:10,cityCode:"150900",cityName:"乌兰察布市"},{id:11,cityCode:"152200",cityName:"兴安盟"},
      {id:12,cityCode:"152500",cityName:"锡林郭勒盟"},{id:13,cityCode:"152900",cityName:"阿拉善盟"}]}, 
      {id:6,cityCode:"210000",cityName:"辽宁省",city:[{id:1,cityCode:"210000",cityName:"辽宁省"},
      {id:2,cityCode:"210100",cityName:"沈阳市"},{id:3,cityCode:"210200",cityName:"大连市"},
      {id:4,cityCode:"210300",cityName:"鞍山市"},{id:5,cityCode:"210400",cityName:"抚顺市"},
      {id:6,cityCode:"210500",cityName:"本溪市"},{id:7,cityCode:"210600",cityName:"丹东市"},
      {id:8,cityCode:"210700",cityName:"锦州市"},{id:9,cityCode:"210800",cityName:"营口市"},
      {id:10,cityCode:"210900",cityName:"阜新市"},{id:11,cityCode:"211000",cityName:"辽阳市"},
      {id:12,cityCode:"211100",cityName:"盘锦市"},{id:13,cityCode:"211200",cityName:"铁岭市"},
      {id:14,cityCode:"211300",cityName:"朝阳市"},{id:15,cityCode:"211400",cityName:"葫芦岛市"}]},
      {id:7,cityCode:"220000",cityName:"吉林省",city:[{id:1,cityCode:"220000",cityName:"吉林省"},
      {id:2,cityCode:"220100",cityName:"长春市"},{id:3,cityCode:"220200",cityName:"吉林市"},
      {id:4,cityCode:"220300",cityName:"四平市"},{id:5,cityCode:"220400",cityName:"辽源市"},
      {id:6,cityCode:"220500",cityName:"通化市"},{id:7,cityCode:"220600",cityName:"白山市"},
      {id:8,cityCode:"220700",cityName:"松原市"},{id:9,cityCode:"220800",cityName:"白城市"},
      {id:10,cityCode:"222400",cityName:"延边州"}]}, 
      {id:8,cityCode:"230000",cityName:"黑龙江省",city:[{id:1,cityCode:"230000",cityName:"黑龙江省"},
      {id:2,cityCode:"230100",cityName:"哈尔滨市"},{id:3,cityCode:"230200",cityName:"齐齐哈尔市"},
      {id:4,cityCode:"230300",cityName:"鸡西市"},{id:5,cityCode:"230400",cityName:"鹤岗市"},
      {id:6,cityCode:"230500",cityName:"双鸭山市"},{id:7,cityCode:"230600",cityName:"大庆市"},
      {id:8,cityCode:"230700",cityName:"伊春市"},{id:9,cityCode:"230800",cityName:"佳木斯市"},
      {id:10,cityCode:"239000",cityName:"七台河市"},{id:11,cityCode:"231000",cityName:"牡丹江市"},
      {id:12,cityCode:"231100",cityName:"黑河市"},{id:13,cityCode:"231200",cityName:"绥化市"},
      {id:14,cityCode:"232700",cityName:"大兴安岭地区"}]},
      {id:9,cityCode:"310000",cityName:"上海市",city:[{id:1,cityCode:"310000",cityName:"上海市"},
      {id:2,cityCode:"310101",cityName:"黄浦区"},{id:3,cityCode:"310104",cityName:"徐汇区"},
      {id:4,cityCode:"310105",cityName:"长宁区"},{id:5,cityCode:"310106",cityName:"静安区"},
      {id:6,cityCode:"310107",cityName:"普陀区"},{id:7,cityCode:"310109",cityName:"虹口区"},
      {id:8,cityCode:"310110",cityName:"杨浦区"},{id:9,cityCode:"310112",cityName:"闵行区"},
      {id:10,cityCode:"310113",cityName:"宝山区"},{id:11,cityCode:"310114",cityName:"嘉定区"},
      {id:12,cityCode:"310115",cityName:"浦东新区"},{id:13,cityCode:"310116",cityName:"金山区"},
      {id:14,cityCode:"310117",cityName:"松江区"},{id:15,cityCode:"310118",cityName:"青浦区"},
      {id:16,cityCode:"310120",cityName:"奉贤区"},{id:17,cityCode:"310151",cityName:"崇明县"},
      ]},
      {id:10,cityCode:"320000",cityName:"江苏省",city:[{id:1,cityCode:"320000",cityName:"江苏省"},
      {id:2,cityCode:"320100",cityName:"南京市"},{id:3,cityCode:"320200",cityName:"无锡市"},
      {id:4,cityCode:"320300",cityName:"徐州市"},{id:5,cityCode:"320400",cityName:"常州市"},
      {id:6,cityCode:"320500",cityName:"苏州市"},{id:7,cityCode:"320600",cityName:"南通市"},
      {id:8,cityCode:"320700",cityName:"连云港市"},{id:9,cityCode:"320800",cityName:"淮安市"},
      {id:10,cityCode:"320900",cityName:"盐城市"},{id:11,cityCode:"321000",cityName:"扬州市"},
      {id:12,cityCode:"321100",cityName:"镇江市"},{id:13,cityCode:"321200",cityName:"泰州市"},
      {id:14,cityCode:"321300",cityName:"宿迁市"}]},
      {id:11,cityCode:"330000",cityName:"浙江省",city:[{id:1,cityCode:"330000",cityName:"浙江省"},
      {id:2,cityCode:"330100",cityName:"杭州市"},{id:3,cityCode:"330200",cityName:"宁波市"},
      {id:4,cityCode:"330300",cityName:"温州市"},{id:5,cityCode:"330400",cityName:"嘉兴市"},
      {id:6,cityCode:"330500",cityName:"湖州市"},{id:7,cityCode:"330600",cityName:"绍兴市"},
      {id:8,cityCode:"330700",cityName:"金华市"},{id:9,cityCode:"330800",cityName:"衢州市"},
      {id:10,cityCode:"330900",cityName:"舟山市"},{id:11,cityCode:"331000",cityName:"台州市"},
      {id:12,cityCode:"331100",cityName:"丽水市"}]},
      {id:12,cityCode:"340000",cityName:"安徽省",city:[{id:1,cityCode:"340000",cityName:"安徽省"},
      {id:2,cityCode:"340100",cityName:"合肥市"},{id:3,cityCode:"340200",cityName:"芜湖市"},
      {id:4,cityCode:"340300",cityName:"蚌埠市"},{id:5,cityCode:"340400",cityName:"淮南市"},
      {id:6,cityCode:"340500",cityName:"马鞍山市"},{id:7,cityCode:"340600",cityName:"淮北市"},
      {id:8,cityCode:"340700",cityName:"铜陵市"},{id:9,cityCode:"340800",cityName:"安庆市"},
      {id:10,cityCode:"341000",cityName:"黄山市"},{id:11,cityCode:"341100",cityName:"滁州市"},
      {id:12,cityCode:"341200",cityName:"阜阳市"},{id:13,cityCode:"341300",cityName:"宿州市"},
      {id:14,cityCode:"341500",cityName:"六安市"},{id:15,cityCode:"341600",cityName:"亳州市"},
      {id:16,cityCode:"341700",cityName:"池州市"},{id:17,cityCode:"341800",cityName:"宣城市"}]},
      {id:13,cityCode:"350000",cityName:"福建省",city:[{id:1,cityCode:"350000",cityName:"福建省"},
      {id:2,cityCode:"350100",cityName:"福州市"},{id:3,cityCode:"350200",cityName:"厦门市"},
      {id:4,cityCode:"350300",cityName:"莆田市"},{id:5,cityCode:"350400",cityName:"三明市"},
      {id:6,cityCode:"350500",cityName:"泉州市"},{id:7,cityCode:"350600",cityName:"漳州市"},
      {id:8,cityCode:"350700",cityName:"南平市"},{id:9,cityCode:"350800",cityName:"龙岩市"},
      {id:9,cityCode:"350900",cityName:"宁德市"}]},         
      {id:14,cityCode:"360000",cityName:"江西省",city:[{id:1,cityCode:"360000",cityName:"江西省"},
      {id:2,cityCode:"360100",cityName:"南昌市"},{id:3,cityCode:"360200",cityName:"景德镇"},
      {id:4,cityCode:"360300",cityName:"萍乡市"},{id:5,cityCode:"360400",cityName:"九江市"},
      {id:6,cityCode:"360500",cityName:"新余市"},{id:7,cityCode:"360600",cityName:"鹰潭市"},
      {id:8,cityCode:"360700",cityName:"赣州市"},{id:9,cityCode:"360800",cityName:"吉安市"},
      {id:10,cityCode:"360900",cityName:"宜春市"},{id:11,cityCode:"361000",cityName:"抚州市"},
      {id:12,cityCode:"361100",cityName:"上饶市"}]},
      {id:15,cityCode:"370000",cityName:"山东省",city:[{id:1,cityCode:"370000",cityName:"山东省"},        
      {id:2,cityCode:"370100",cityName:"济南市"},{id:3,cityCode:"370200",cityName:"青岛市"},
      {id:4,cityCode:"370300",cityName:"淄博市"},{id:5,cityCode:"370400",cityName:"枣庄市"},
      {id:6,cityCode:"370500",cityName:"东营市"},{id:7,cityCode:"370600",cityName:"烟台市"},
      {id:8,cityCode:"370700",cityName:"潍坊市"},{id:9,cityCode:"370800",cityName:"济宁市"},
      {id:10,cityCode:"370900",cityName:"泰安市"},{id:11,cityCode:"371000",cityName:"威海市"},
      {id:12,cityCode:"371100",cityName:"日照市"},{id:13,cityCode:"371300",cityName:"临沂市"},
      {id:14,cityCode:"371400",cityName:"德州市"},{id:15,cityCode:"371500",cityName:"聊城市"},
      {id:16,cityCode:"371600",cityName:"滨州市"},{id:17,cityCode:"371700",cityName:"菏泽市"}]}, 
      {id:16,cityCode:"410000",cityName:"河南省",city:[{id:1,cityCode:"410000",cityName:"河南省"},
      {id:2,cityCode:"410100",cityName:"郑州市"},{id:3,cityCode:"410200",cityName:"开封市"},
      {id:4,cityCode:"410300",cityName:"洛阳市"},{id:5,cityCode:"410400",cityName:"平顶山市"},
      {id:6,cityCode:"410500",cityName:"安阳市"},{id:7,cityCode:"410600",cityName:"鹤壁市"},
      {id:8,cityCode:"410700",cityName:"新乡市"},{id:9,cityCode:"410800",cityName:"焦作市"},
      {id:10,cityCode:"410900",cityName:"濮阳市"},{id:11,cityCode:"411000",cityName:"许昌市"},
      {id:12,cityCode:"411100",cityName:"漯河市"},{id:13,cityCode:"412000",cityName:"三门峡市"},
      {id:14,cityCode:"411300",cityName:"南阳市"},{id:15,cityCode:"411400",cityName:"商丘市"},
      {id:16,cityCode:"411500",cityName:"信阳市"},{id:17,cityCode:"416000",cityName:"周口市"},
      {id:18,cityCode:"411700",cityName:"驻马店市"},{id:19,cityCode:"41900100",cityName:"济源市"}]},
      {id:17,cityCode:"420000",cityName:"湖北省",city:[{id:1,cityCode:"420000",cityName:"湖北省"},
      {id:2,cityCode:"420100",cityName:"武汉市"},{id:3,cityCode:"420200",cityName:"黄石市"},
      {id:4,cityCode:"420300",cityName:"十堰市"},{id:5,cityCode:"420500",cityName:"宜昌市"},
      {id:6,cityCode:"420600",cityName:"襄阳市"},{id:7,cityCode:"420700",cityName:"鄂州市"},
      {id:8,cityCode:"420800",cityName:"荆门市"},{id:9,cityCode:"420900",cityName:"孝感市"},
      {id:10,cityCode:"421000",cityName:"荆州市"},{id:11,cityCode:"421100",cityName:"黄冈市"},
      {id:12,cityCode:"421200",cityName:"咸宁市"},{id:13,cityCode:"421300",cityName:"随州市"},
      {id:14,cityCode:"422800",cityName:"恩施州"},{id:15,cityCode:"42900400",cityName:"仙桃市"},
      {id:16,cityCode:"42900500",cityName:"潜江市"},{id:17,cityCode:"42900600",cityName:"天门市"},
      {id:18,cityCode:"42902100",cityName:"神农架林区"}]},
      {id:18,cityCode:"430000",cityName:"湖南省",city:[{id:1,cityCode:"430000",cityName:"湖南省"},
      {id:2,cityCode:"430100",cityName:"长沙市"},{id:3,cityCode:"430200",cityName:"株洲市"},
      {id:4,cityCode:"430300",cityName:"湘潭市"},{id:5,cityCode:"430400",cityName:"衡阳市"},
      {id:6,cityCode:"430500",cityName:"邵阳市"},{id:7,cityCode:"430600",cityName:"岳阳市"},
      {id:8,cityCode:"430700",cityName:"常德市"},{id:9,cityCode:"430800",cityName:"张家界市"},
      {id:10,cityCode:"439000",cityName:"益阳市"},{id:11,cityCode:"431000",cityName:"郴州市"},
      {id:12,cityCode:"431100",cityName:"永州市"},{id:13,cityCode:"431200",cityName:"怀化市"},
      {id:14,cityCode:"431300",cityName:"娄底市"},{id:15,cityCode:"433100",cityName:"湘西州"}]},       
      {id:19,cityCode:"440000",cityName:"广东省",city:[{id:1,cityCode:"440000",cityName:"广东省"},
      {id:2,cityCode:"440100",cityName:"广州市"},{id:3,cityCode:"440200",cityName:"韶关市"},
      {id:4,cityCode:"440300",cityName:"深圳市"},{id:5,cityCode:"440400",cityName:"珠海市"},
      {id:6,cityCode:"440500",cityName:"汕头市"},{id:7,cityCode:"440600",cityName:"佛山市"},
      {id:8,cityCode:"440700",cityName:"江门市"},{id:9,cityCode:"440800",cityName:"湛江市"},
      {id:10,cityCode:"440900",cityName:"茂名市"},{id:11,cityCode:"441200",cityName:"肇庆市"},
      {id:12,cityCode:"441300",cityName:"惠州市"},{id:13,cityCode:"441400",cityName:"梅州市"},
      {id:14,cityCode:"441500",cityName:"汕尾市"},{id:15,cityCode:"441600",cityName:"河源市"},
      {id:16,cityCode:"441700",cityName:"阳江市"},{id:17,cityCode:"441800",cityName:"清远市"},
      {id:18,cityCode:"441900",cityName:"东莞市"},{id:19,cityCode:"442000",cityName:"中山市"},
      {id:20,cityCode:"445100",cityName:"潮州市"},{id:21 ,cityCode:"445200",cityName:"揭阳市"},
      {id:20,cityCode:"445300",cityName:"云浮市"}]},        
      {id:20,cityCode:"450000",cityName:"广西壮族自治区",city:[{id:1,cityCode:"450000",cityName:"广西壮族自治区"},
      {id:2,cityCode:"450100",cityName:"南宁市"},{id:3,cityCode:"450200",cityName:"柳州市"},
      {id:4,cityCode:"450300",cityName:"桂林市"},{id:5,cityCode:"450400",cityName:"梧州市"},
      {id:6,cityCode:"450500",cityName:"北海市"},{id:7,cityCode:"450600",cityName:"防城港市"},
      {id:8,cityCode:"450700",cityName:"钦州市"},{id:9,cityCode:"450800",cityName:"贵港市"},
      {id:10,cityCode:"450900",cityName:"玉林市"},{id:11,cityCode:"451000",cityName:"百色市"},
      {id:12,cityCode:"451100",cityName:"贺州市"},{id:13,cityCode:"451200",cityName:"河池市"},
      {id:14,cityCode:"451300",cityName:"来宾市"},{id:15,cityCode:"451400",cityName:"崇左市"}]},         
      {id:21,cityCode:"460000",cityName:"海南省",city:[{id:1,cityCode:"460000",cityName:"海南省"},
      {id:2,cityCode:"460100",cityName:"海口市"},{id:3,cityCode:"460200",cityName:"三亚市"},
      {id:4,cityCode:"460300",cityName:"三沙市"},{id:5,cityCode:"460400",cityName:"儋州市"},
      {id:6,cityCode:"469000",cityName:"省直辖县级行政区划"}]},
      {id:22,cityCode:"500000",cityName:"重庆市",city:[{id:1,cityCode:"500000",cityName:"重庆市"},
      {id:2,cityCode:"500101",cityName:"万州区"},{id:3,cityCode:"500102",cityName:"涪陵区"},
      {id:4,cityCode:"500103",cityName:"渝中区"},{id:5,cityCode:"500104",cityName:"大渡口区"},
      {id:6,cityCode:"500105",cityName:"江北区"},{id:7,cityCode:"500106",cityName:"沙坪坝区"},
      {id:8,cityCode:"500107",cityName:"九龙坡区"},{id:9,cityCode:"500108",cityName:"南岸区"},
      {id:10,cityCode:"500109",cityName:"北碚区"},{id:11,cityCode:"500110",cityName:"綦江区"},
      {id:12,cityCode:"500111",cityName:"大足区"},{id:13,cityCode:"500112",cityName:"渝北区"},
      {id:14,cityCode:"500113",cityName:"巴南区"},{id:15,cityCode:"500114",cityName:"黔江区"},
      {id:16,cityCode:"500115",cityName:"长寿区"},{id:17,cityCode:"500116",cityName:"江津区"},
      {id:18,cityCode:"500117",cityName:"合川区"},{id:19,cityCode:"500118",cityName:"永川区"},
      {id:20,cityCode:"500119",cityName:"南川区"},{id:21,cityCode:"500120",cityName:"璧山区"},
      {id:22,cityCode:"500151",cityName:"铜梁区"},{id:23,cityCode:"500152",cityName:"潼南区"},
      {id:24,cityCode:"500153",cityName:"荣昌区"},{id:25,cityCode:"500154",cityName:"开州区"},
      {id:26,cityCode:"500155",cityName:"梁平区"},{id:27,cityCode:"500156",cityName:"武隆区"},
      {id:28,cityCode:"500229",cityName:"城口县"},{id:29,cityCode:"500230",cityName:"丰都县"},
      {id:30,cityCode:"500231",cityName:"垫江县"},{id:31,cityCode:"500233",cityName:"忠县"},
      {id:32,cityCode:"500235",cityName:"云阳县"},{id:33,cityCode:"500236",cityName:"奉节县"},
      {id:34,cityCode:"500237",cityName:"巫山县"},{id:35,cityCode:"500238",cityName:"巫溪县"},
      {id:36,cityCode:"500240",cityName:"石柱县"},{id:37,cityCode:"500241",cityName:"秀山县"},
      {id:38,cityCode:"500242",cityName:"酉阳县"},{id:39,cityCode:"500243",cityName:"彭水县"}]},         
      {id:23,cityCode:"510000",cityName:"四川省",city:[{id:1,cityCode:"510000",cityName:"四川省"},
      {id:2,cityCode:"510100",cityName:"成都市"},{id:3,cityCode:"510300",cityName:"自贡市"},
      {id:4,cityCode:"510400",cityName:"攀枝花市"},{id:5,cityCode:"510500",cityName:"泸州市"},
      {id:6,cityCode:"510600",cityName:"德阳市"},{id:7,cityCode:"510700",cityName:"绵阳市"},
      {id:8,cityCode:"510800",cityName:"广元市"},{id:9,cityCode:"510900",cityName:"遂宁市"},
      {id:10,cityCode:"511000",cityName:"内江市"},{id:11,cityCode:"511100",cityName:"乐山市"},
      {id:12,cityCode:"511300",cityName:"南充市"},{id:13,cityCode:"511400",cityName:"眉山市"},
      {id:14,cityCode:"511500",cityName:"宜宾市"},{id:15,cityCode:"511600",cityName:"广安市"},
      {id:16,cityCode:"511700",cityName:"达州市"},{id:17,cityCode:"511800",cityName:"雅安市"},
      {id:18,cityCode:"511900",cityName:"巴中市"},{id:19,cityCode:"512000",cityName:"资阳市"},
      {id:20,cityCode:"513200",cityName:"阿坝州"},{id:21,cityCode:"513300",cityName:"甘孜州"},
      {id:22,cityCode:"513400",cityName:"凉山州"}]},
      {id:24,cityCode:"520000",cityName:"贵州省",city:[{id:1,cityCode:"520000",cityName:"贵州省"},
      {id:2,cityCode:"520100",cityName:"贵阳市"},{id:3,cityCode:"520200",cityName:"六盘水市"},
      {id:4,cityCode:"520300",cityName:"遵义市"},{id:5,cityCode:"520400",cityName:"安顺市"},
      {id:6,cityCode:"520500",cityName:"毕节市"},{id:7,cityCode:"520600",cityName:"铜仁市"},
      {id:8,cityCode:"522300",cityName:"黔西南州"},{id:9,cityCode:"522600",cityName:"黔东南州"},
      {id:10,cityCode:"522700",cityName:"黔南州"}]},
      {id:25,cityCode:"530000",cityName:"云南省",city:[{id:1,cityCode:"530000",cityName:"云南省"},
      {id:2,cityCode:"530100",cityName:"昆明市"},
      {id:3,cityCode:"530300",cityName:"曲靖市"},{id:4,cityCode:"530400",cityName:"玉溪市"},
      {id:5,cityCode:"530500",cityName:"保山市"},{id:6,cityCode:"530600",cityName:"昭通市"},
      {id:7,cityCode:"530700",cityName:"丽江市"},{id:8,cityCode:"530800",cityName:"普洱市"},
      {id:9,cityCode:"530900",cityName:"临沧市"},{id:10,cityCode:"532300",cityName:"楚雄州"},
      {id:11,cityCode:"532500",cityName:"红河州"},{id:12,cityCode:"532600",cityName:"文山州"},
      {id:13,cityCode:"532800",cityName:"西双版纳州"},{id:14,cityCode:"532900",cityName:"大理州"},
      {id:15,cityCode:"533100",cityName:"德宏州"},{id:16,cityCode:"533300",cityName:"怒江州"},
      {id:17,cityCode:"533400",cityName:"迪庆州"}]},
      {id:26,cityCode:"540000",cityName:"西藏自治区",city:[{id:1,cityCode:"540000",cityName:"西藏自治区"},
      {id:2,cityCode:"540100",cityName:"拉萨市"},{id:3,cityCode:"540200",cityName:"日喀则市"},
      {id:4,cityCode:"540300",cityName:"昌都市"},{id:5,cityCode:"540400",cityName:"林芝市"},
      {id:6,cityCode:"540500",cityName:"山南市"},{id:7,cityCode:"540600",cityName:"那曲市"},
      {id:8,cityCode:"54250000",cityName:"阿里地区"}]},
      {id:27,cityCode:"610000",cityName:"陕西省",city:[{id:1,cityCode:"610000",cityName:"陕西省"},
      {id:2,cityCode:"610100",cityName:"西安市"},{id:3,cityCode:"610200",cityName:"铜川市"},
      {id:4,cityCode:"610300",cityName:"宝鸡市"},{id:5,cityCode:"610400",cityName:"咸阳市"},
      {id:6,cityCode:"610500",cityName:"渭南市"},{id:7,cityCode:"610600",cityName:"延安市"},
      {id:8,cityCode:"610700",cityName:"汉中市"},{id:9,cityCode:"610800",cityName:"榆林市"},
      {id:10,cityCode:"610900",cityName:"安康市"},{id:11,cityCode:"611000",cityName:"商洛市"}]},
      {id:28,cityCode:"620000",cityName:"甘肃省",city:[{id:1,cityCode:"620000",cityName:"甘肃省"},
      {id:2,cityCode:"620100",cityName:"兰州市"},{id:3,cityCode:"620200",cityName:"嘉峪关市"},
      {id:4,cityCode:"620300",cityName:"金昌市"},{id:5,cityCode:"620400",cityName:"白银市"},
      {id:6,cityCode:"620500",cityName:"天水市"},{id:7,cityCode:"620600",cityName:"武威市"},
      {id:8,cityCode:"620700",cityName:"张掖市"},{id:9,cityCode:"620800",cityName:"平凉市"},
      {id:10,cityCode:"620900",cityName:"酒泉市"},{id:11,cityCode:"621000",cityName:"庆阳市"},
      {id:12,cityCode:"621100",cityName:"定西市"},{id:13,cityCode:"621200",cityName:"陇南市"},
      {id:14,cityCode:"622900",cityName:"临夏州"},{id:15,cityCode:"623000",cityName:"甘南州"}]},
      {id:29,cityCode:"630000",cityName:"青海省",city:[{id:1,cityCode:"630000",cityName:"青海省"},
      {id:2,cityCode:"630100",cityName:"西宁市"},{id:3,cityCode:"630200",cityName:"海东市"},
      {id:4,cityCode:"630300",cityName:"黄南州"},{id:5,cityCode:"630500",cityName:"海南州"},
      {id:6,cityCode:"630600",cityName:"果洛州"},{id:7,cityCode:"630700",cityName:"青海省"},
      {id:8,cityCode:"630800",cityName:"青海省"},{id:8,cityCode:"632200",cityName:"海北州"}]},
      {id:30,cityCode:"640000",cityName:"宁夏回族自治区",city:[{id:1,cityCode:"640000",cityName:""},
      {id:2,cityCode:"640100",cityName:"银川市"},{id:3,cityCode:"640200",cityName:"石嘴山市"},
      {id:4,cityCode:"640300",cityName:"吴忠市"},{id:5,cityCode:"640400",cityName:"固原市"},
      {id:6,cityCode:"640500",cityName:"中卫市"}]},
      {id:31,cityCode:"650000",cityName:"新疆维吾尔自治区",city:[{id:1,cityCode:"650000",cityName:"新疆维吾尔自治区"},
      {id:2,cityCode:"650100",cityName:"乌鲁木齐市"},{id:3,cityCode:"650200",cityName:"克拉玛依市"},
      {id:4,cityCode:"650400",cityName:"吐鲁番市"},{id:5,cityCode:"650500",cityName:"哈密市"},
      {id:6,cityCode:"652300",cityName:"昌吉州"},{id:7,cityCode:"652700",cityName:"博州"},
      {id:8,cityCode:"652800",cityName:"巴州"},{id:9,cityCode:"652900",cityName:"阿克苏地区"},
      {id:10,cityCode:"653000",cityName:"克州"},{id:11,cityCode:"653100",cityName:"喀什地区"},
      {id:12,cityCode:"653200",cityName:"和田地区"},{id:13,cityCode:"654000",cityName:"伊犁州"},
      {id:14,cityCode:"654200",cityName:"塔城地区"},{id:15,cityCode:"654300",cityName:"阿勒泰地区"},
      {id:16,cityCode:"659000",cityName:"直辖县级政区"}]},
      {id:32,cityCode:"710000",cityName:"台湾省",city:[{id:1,cityCode:"710000",cityName:"台湾省"}]},
      {id:33,cityCode:"810000",cityName:"香港特别行政区",city:[{id:1,cityCode:"810000",cityName:"香港特别行政区"}]},
        { id: 34, cityCode: "820000", cityName: "澳门特别行政区", city: [{ id: 1, cityCode: "820000", cityName: "澳门特别行政区" }] }],
      totalRm: 0,
      totalZx: 0,
      visable:false,
    };
  },

  created() {
    //         axios
    //   .get("/api/test")
    //   .then((res) => {
    //     console.log(res,66);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // this.list();
    // this.onLoad()
    setTimeout(()=>{
      // this.ads = true
        this.$nextTick(()=>{
          // this.ads = true
        this.openModal()

        })
    }, 2500)
  
  },
    beforeRouteLeave(to, from, next) {
	//scroll要先在data里面赋值一个0 scroll:0
	//el-main是你这个页面的主体class名称，写你自己的名称。
    this.scroll = document.getElementsByClassName("el-main")[0].scrollTop;
    next();
  },
  activated() {
    // this.getGroupImgList();
    this.$nextTick(() => {
      setTimeout(() => {
        document.getElementsByClassName("el-main")[0].scrollTop = this.scroll;
      }, 0);
    });
  },


  methods: {
    // 热门/最新切换
    change(index) {
      console.log(index);
      this.actIndex = index;
      if (index == 0) {
        this.dataList = []
        this.pageNumber = 1
        // this.getRMnum()

        this.onLoad()
        this.rm = true;
        this.zx = false;
      } else {
        this.pageNumber1 = 1
        // this.getZXnum()

        this.onLoad1()
        this.rm = false;
        this.zx = true;
      }
    },
    // 格式化时间
    filterTime(value) {
      if (value != null || value != "") {
        let val = value;
        let momentObj = moment(val * 1).format("YYYY-MM-DD");
        return momentObj;
      } else {
        return "";
      }
    },
    filterTimePIC(value) {
      if (value != null || value != "") {
        let val = value;
        let momentObj = moment(val * 1).format("YYMMDD");
        console.log(momentObj,"156666");
        return momentObj;
      } else {
        return "";
      }
    },
    // 格式化地区
      filterCity(val){
      
        let cityCode = val
      if(cityCode != null || cityCode !=""){
        let val = cityCode.toString()
        let twoCode = val.slice(0,2)
        let arr = this.province.filter((item)=>{
            return item.cityCode.toString().slice(0,2) == twoCode
        })
        let brr = arr[0].city.filter((item)=>{
            return item.cityCode == cityCode
        })
        let pName = arr[0].cityName
        let cName = brr[0].cityName
        // let pName
        // let cName
        // if(arr[0]!=null && arr[0]!=''){
        // pName = arr[0].cityName

        // }
        // if(brr[0]!=null && brr[0]!=''){
        // cName = brr[0].cityName

        // }
        // console.log(arr);
        // console.log(brr);
        // console.log(twoCode);
        // console.log(val);
        // console.log(pName+cName);
        return pName+'-'+cName
      }else{
        return '天上人间'
      }
      
    },
    // // 请求数据
    getListdata(){
      setTimeout(()=>{
        let data = {
          pageNumber : this.pageNumber++
       }
        getRMlist(data).then((res) => {
          this.totalZx = res.data.total
            if (this.refreshing) {
            this.dataList = [];
            this.refreshing = false;
        }
        this.dataList = this.dataList.concat(res.data.data);
    // this.filterTimePIC(1662926669000)

        if (this.dataList.length >= this.totalZx) {
                this.finished = true;
                return;
              }
              this.loading = false;
       })
      },500)
    },
    // 加载
     onLoad(){
      // this.getRMnum()
      setTimeout(()=>{
        this.getListdata()
      },200)
    },
    // 下拉
     onRefresh() {
      // 清空列表数据
      this.finished = false
      this.pageNumber = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.getListdata()
    },



    // 请求数据
    getListdata1(){
      setTimeout(()=>{
        let data = {
          pageNumber : this.pageNumber1++
       }
        getZXlist(data).then((res) => {
          this.totalRm = res.data.total
            if (this.refreshing1) {
            this.dataList1 = [];
            this.refreshing1 = false;
        }
        this.dataList1 = this.dataList1.concat(res.data.data);
        if (this.dataList1.length >= this.totalRm) {
                this.finished1 = true;
                return;
              }
              this.loading1 = false;
       })
      },500)
    },
    // 加载
     onLoad1(){
      // this.getZXnum()
      setTimeout(()=>{
      this.getListdata1()
      },200)
    },
    // 下拉
     onRefresh1() {
      // 清空列表数据
      this.finished1 = false
      this.pageNumber1= 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading1 = true
      this.getListdata1()
    },
  // 热门数量
 async getRMnum(){
   await getRMnum().then((res)=>{
      console.log(res);
      if(res.status == 200){
        this.RMnum = res.data.length
      }
    })
  },
  async getZXnum(){
   await getZXnum().then((res)=>{
      console.log(res);
      if(res.status == 200){
        this.ZXnum = res.data.length
      }
    })
  },




    // 详情页
    detail(val){
        // console.log(val,88);
      this.$router.push({
        path: "/detail",
        query: { id: val.id },
      });
      let time = this.$getNYRSFM()
      let uuid = uuidv4()
      if(this.$store.state.user.loginStatus){
        this.$operLog('查看详情',uuid,this.$store.state.user.userInfo.username,this.$store.state.user.userInfo.usertype,this.$store.state.user.userInfo.createTime,window.location.host,time)
      }else{
        this.$operLog('查看详情',uuid,'','','',window.location.host,time)
      }
    },
     //处理破图
    moveErrorImg(event) {
        event.currentTarget.src = require("../../assets/images/err.jpg");
        event.currentTarget.style.width = "auto";
        return true;
      },
    // 
    searchBox(){
      this.$router.push({
        path:"/search"
      }).catch(()=>{})
    },
    closeAds(){
      this.ads = false
    },
    openModal() { 
      this.visable = true
      // Dialog.confirm({
      //   title: '公告',
      //   message: '弹窗内容',
      //   visable: this.visable,
      //   "confirm-button-text":"已知悉",
      // })
      //   .then(() => {
      //     // on confirm
      //   })
      //   .catch(() => {
      //     // on cancel
      //   });
    }

  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container {
  width: 100%;
  background-color: #f5f5f5;
  height: calc(100% - 40px);
  
}
 .ads{
  position: absolute;
  top: 30%;
  left: 15%;
  width: 65%;
  height: 35%;
  /* background-color: rgba(255, 182, 193, 0.9); */
  background-color: #fff;

  /* background: url(./tip.png) no-repeat; */
  /* background-size: 100% 100%; */
  z-index: 20000;
  border-radius: 5px;
  padding: 10px 10px;
  font-size: 14px;
}
.ads .ld{
  width: 100%;
  height: 60px;
}
.ads .ld img{
  width: 60px;
  height: 100%;
  margin-left: 10px;
}
.ads .closeBtn{
  width: 100px;
  height: 35px;
  background-color: #ec4899;
  color: #fff;
  font-size: 16px;
  line-height: 35px;
  border-radius: 15px;
  position: absolute;
  bottom: 30px;
  right: 32%;
}
.ads .head{
  width: 100%;
  height: 35px;
  font-size: 18px;
  line-height: 28px;
  color: #ec4899;
}
.ads .font{
  font-size: 16px;
  /* text-align: left; */
}
.ads .cross{
  position: absolute;
  right: 12px;
  font-size: 22px;
  top: 12px;
}
#container .header {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  color: #ec4899;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.title {
  width: 250px;
  text-align: center;
  height: 60px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title ul {
  font-size: 18px;
  /* display: flex; */
  /* margin-right: 10px; */
  padding: 0 8px;
  font-size: 16px;
}
.activecolor {
  border-bottom: 2px solid #ec4899 !important;
  font-size: 18px !important;
}
.title ul li {
  padding-bottom: 10px;
  cursor: pointer;
}
.content {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: scroll;
  padding: 0 10px;
  background-color: #f5f5f5;
}
.content .table {
  width: 100%;
  height: 100%;
  /* background-color: #f5f5f5; */
}
.content .table .list {
  box-sizing: border-box;
  width: 100%;
  height: 176px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
}
.content .table .list .left {
  width: 65%;
  height: 100%;
}
.content .table .list .left2 {
  width: 100%;
  height: 100%;
}
.content .table .list .right {
  width: 35%;
  height: 100%;
}
.content .table .list .right img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.content .table .list .left .tit {
  font-size: 16px;
  height: 20px;
  margin-left: -65px;
}
.content .table .list .left .tip {
  height: 15px;
  /* font-size: 12px; */
}
.bottom .city {
  color: #ec4899;
}
.header .search{
  position: absolute; 
  right: 15px;
}
</style>